import { Link } from 'gatsby'
import React, { FC } from 'react'
import Layout from '../components/layout/Layout'

const NotFoundPage: FC = () => (
  <Layout>
    <h1>404: Page not found.</h1>
    <p>
      You've hit the void. <Link to="/">Go back.</Link>
    </p>
  </Layout>
)

export default NotFoundPage
